import http from '@/utils/httpRequest'


export function getList(params) {
  	return http({
          url: http.adornUrl('/sys/role/list'),
          method: 'get',
          params: params
        })
} 

export function deleteRole(ids = []) {
	return http({
            url: http.adornUrl("/sys/role/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}


export function getRoleUserList(params) {
        return http({
        url: http.adornUrl('/sys/role/listRoleUser'),
        method: 'get',
        params: params
      })
} 

export function deleteRoleUser(ids = []) {
	return http({
            url: http.adornUrl("/sys/role/deleteRoleUser"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

export function selectList() {
  return http({
        url: http.adornUrl(`/sys/role/select`),
        method: "get"
        })
}

export function selectMyRole() {
  return http({
        url: http.adornUrl(`/sys/role/selectMyRole`),
        method: "get"
        })
}

export function getById(id) {
	return http({
        url: http.adornUrl(`/sys/role/info/${id}`),
        method: "get"
        })
}
 
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/sys/role/${!data.roleId ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}