<template>
  <el-dialog
    :title="!dataForm.id ? '新增用户信息' : '修改用户信息'"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :lock-scroll="false"
    :visible.sync="visible"
  >
    <!-- <div class="mod-config"> -->
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      v-loading="isOnClick"
      label-width="90px"
    >
      <el-form-item label="子用户账号" prop="userNo">
        <el-input
          v-model="dataForm.userNo"
          placeholder="子用户账号"
          :disabled="
            dataForm.id != null &&
              dataForm.id != '' &&
              dataForm.id != '0' &&
              dataForm.userNo != null
          "
        ></el-input>
      </el-form-item>
      <el-form-item label="子用户名称" prop="userName">
        <el-input v-model="dataForm.userName" placeholder="子用户名称"></el-input>
      </el-form-item>
      
      <el-form-item label="性别"> 
         <sysDictRadio v-model="dataForm.sex"  dictType="sex" />
      </el-form-item>

      <el-form-item
        v-if="!dataForm.id"
        label="密码"
        prop="password"
        :class="{ 'is-required': !dataForm.id }"
      >
        <el-input
          v-model="dataForm.password"
          type="password"
          placeholder="密码"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="!dataForm.id"
        label="确认密码"
        prop="comfirmPassword"
        :class="{ 'is-required': !dataForm.id }"
      >
        <el-input
          v-model="dataForm.comfirmPassword"
          type="password"
          placeholder="确认密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
      </el-form-item>
      
      <!--</el-form-item>-->
      <el-form-item label="后台角色" size="mini" prop="roleIdList">
        <el-checkbox-group v-model="dataForm.roleIdList">
          <template  v-for="role in roleList">
            <el-checkbox 
              :key="role.roleId" 
              :label="role.roleId"
              >{{ role.roleName }}
            </el-checkbox>
          </template>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="状态" size="mini" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0">禁用</el-radio>
          <el-radio :label="1">正常</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnClick"
        >确定</el-button
      >
    </span>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
import { treeDataTranslate } from "@/utils";
//import { isMobile } from '@/utils/validate'
import { encryptDes } from "@/utils/des";
import { validatorPassword } from '@/utils/validate'
import { getById , saveOrUpdate } from '@/api/sys/user'
import { selectMyRole } from '@/api/sys/role' 
export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!this.dataForm.id && !validatorPassword(value)) {
        callback(new Error('密码要求长度8~20位,并且不能为纯数字或纯字母!'))
      } else {
        callback()
      }
    }
    var validateComfirmPassword = (rule, value, callback) => {
      if (!this.dataForm.id && !validatorPassword(value)) {
        callback(new Error('确认密码要求长度8~20位,并且不能为纯数字或纯字母!'))
      } else if (this.dataForm.password !== value) {
        callback(new Error('确认密码与密码输入不一致'))
      } else {
        callback()
      }
    }

    // var validateMobile = (rule, value, callback) => {
    //   if (!isMobile(value)) {
    //     callback(new Error('手机号格式错误'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      visible: false,
      isOnClick: false,
      loadend: false,
      roleList: [],
      departList:[] ,
      dataForm: {
        id: 0,
        userName: "",
        password: "",
        comfirmPassword: "",
        userNo: "",
        sex: 1,
        
        mobile: "",
        roleIdList: [], 
        status: 1 
        
      },
      dataRule: {
        userName: [
          { required: true, message: "用户名称不能为空", trigger: "blur" }
        ],
        password: [{ validator: validatePassword, trigger: "blur" }],
        comfirmPassword: [
          { validator: validateComfirmPassword, trigger: "blur" }
        ],
        userNo: [
          { required: true, message: "用户账号不能为空", trigger: "blur" }
        ],
        
      }
    };
  },  
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
 
      selectMyRole().then(({ data }) => {
          this.roleList = data && data.code === 0 ? data.data : [];
        })
        .then(() => {
          this.visible = true;
          this.$nextTick(() => {
            this.$refs["dataForm"].resetFields();
            if (this.dataForm.id) {
              this.dataForm.password = null;
            }
          });
        })
        .then(() => {
          if (this.dataForm.id) {
            getById(this.dataForm.id).then(({ data }) => {
              if (data && data.code === 0) {
                this.dataForm.userName = data.data.username;
                 
                this.dataForm.userNo = data.data.userNo;
                this.dataForm.sex = data.data.sex;
                this.dataForm.mobile = data.data.mobile;
                this.dataForm.roleIdList = data.data.roleIdList
                  ? data.data.roleIdList
                  : [];

                this.dataForm.status = data.data.status;
                  
              }
            });
          } else {
            // this.deptListTreeSetCurrentNode()
          }
        });
    },
     
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          // 密码加密
          const n_pass = this.dataForm.password
            ? encryptDes(this.dataForm.password)
            : null;

          this.isOnClick = true;
          saveOrUpdate({
              userId: this.dataForm.id || undefined,
              username: this.dataForm.userName,
              password: n_pass,
              userNo: this.dataForm.userNo,
              mobile: this.dataForm.mobile, 
              sex: this.dataForm.sex,
              status: this.dataForm.status, 
              roleIdList: this.dataForm.roleIdList
            }).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "操作成功",
                  type: "success"
                });
                this.isOnClick = false;
                this.visible = false;
                this.$emit("refreshDataList");
                // this.$router.push({ name: "sys_user" });
              } else {
                this.$message.error(data.msg);
              }
            })
            .finally(() => {
              this.isOnClick = false;
            });
        }
      });
    }
  }
};
</script>
